import service from "@/utils/request";

/**
 * 获取所有题库
 * @param current
 * @returns {AxiosPromise}
 */
export function quizGetAll(current) {
    return service(
        {
            url: 'quiz/getAll',
            method: 'post',
            params: {
                current
            }
        })
}
