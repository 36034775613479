<template>
  <div>
    <el-table
        :data="test.records"
        stripe
        @row-click="rowClick"
        style="width: 100%">
      <el-table-column
          prop="title"
          label="题目名称"
          width="180">
      </el-table-column>
      <el-table-column
          prop="description"
          label="题目描述"
         >
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="type"-->
      <!--          label="分类">-->
      <!--        <template slot-scope="scope">-->
      <!--          {{ getTag(scope.row.type) }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
          prop="codeLengthLimit"
          width="100"
          label="代码长度限制KB">

      </el-table-column>
      <el-table-column
          prop="javaMemoryLimit"
          width="100"
          label="代码长度限制kb">

      </el-table-column>
      <el-table-column
          prop="author"
          width="180"
          label="作者信息">
        <!--        <template slot-scope="scope">-->
        <!--          <little-tag :text="scope.row.tags"></little-tag>-->
        <!--        </template>-->
      </el-table-column>
    </el-table>
    <el-row class="bottom">
      <el-pagination
          background
          :current-page="test.current"
          @current-change="handleChange"
          layout="total, prev, pager, next, jumper"
          :page-size="test.size"
          :total="test.total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {quizGetAll} from "@/api/quiz";

export default {
  name: "home-page",
  data() {
    return {
      test: {},
    }
  },
  mounted() {
    this.handleChange(1)
  },
  methods: {
    handleChange(number) {
      quizGetAll(number).then(result => {
        console.log(result.data.quiz)
        this.test = result.data.quiz;
      }).catch(error => {
        this.$message({
          message: error.message,
          type: "error",
          duration: 2 * 1000
        })
      })
    },
    rowClick(row, column, event) {

    }
  }
}
</script>

<style scoped>

</style>